<template>
  <div class="d-flex justify-center align-center _100vh _bg-default">
    <v-card
      height="100px"
      width="100px"
      class="hover"
      v-ripple="{ class: `blue--text` }"
      @click.native="clickable"
    ></v-card>
  </div>
</template>

<script>
export default {
  methods: {
    clickable() {
      console.log("tol");
    }
  }
};
</script>

<style scoped>
.hover:hover {
  cursor: pointer;
}
</style>
